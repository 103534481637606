<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar
      title="Confirm Bonded Warehouse Addres"
      :showbroker="false"
      :showaction="false"
      :showback="true"
    />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip
          :showBrokerButton="false"
          :showPie="false"
          :showWine="false"
        />
      </div>

      <div class="col-span-8">
        <div class="grid grid-cols-5 gap-4 m-20">
          <div class="col-span-2">
            <span class="oeno_portfolio_winetitle pt-10"
              >Warehouse / Company name</span
            >
          </div>
          <div class="col-span-3">
            <md-field class="md-field-small">
              <md-input
                v-model="bondDetails.company_name"
                name="floating_company_name"
                id="floating_company_name"
                type="text"
                required
              ></md-input>
              <div
                v-if="submitted && !$v.bondDetails.company_name.required"
                class="invalid-feedback"
              >
                Company name is required
              </div>
            </md-field>
          </div>

          <div class="col-span-2">
            <span class="oeno_portfolio_winetitle pt-10">Address</span>
          </div>
          <div class="col-span-3">
            <md-field class="md-field-small">
              <md-input
                v-model="bondDetails.company_address"
                name="floating_company_address"
                id="floating_company_address"
                type="text"
                required
              ></md-input>
              <div
                v-if="submitted && !$v.bondDetails.company_address.required"
                class="invalid-feedback"
              >
                Company address is required
              </div>
            </md-field>
          </div>

          <div class="col-span-2">
            <span class="oeno_portfolio_winetitle pt-10">Point of Contact</span>
          </div>
          <div class="col-span-3">
            <md-field class="md-field-small">
              <md-input
                v-model="bondDetails.point_of_contact"
                name="floating_point_of_contact"
                id="floating_point_of_contact"
                type="text"
                required
              ></md-input>
              <div
                v-if="submitted && !$v.bondDetails.point_of_contact.required"
                class="invalid-feedback"
              >
                Point of Contact is required
              </div>
            </md-field>
          </div>

          <div class="col-span-2">
            <span class="oeno_portfolio_winetitle pt-10">Phone</span>
          </div>
          <div class="col-span-3">
            <md-field class="md-field-small">
              <md-input
                v-model="bondDetails.point_of_contact_phone"
                name="floating_point_of_contact_phone"
                id="floating_point_of_contact_phone"
                type="text"
                required
              ></md-input>
            </md-field>
          </div>

          <div class="col-span-2">
            <span class="oeno_portfolio_winetitle pt-10">Email</span>
          </div>
          <div class="col-span-3">
            <md-field class="md-field-small">
              <md-input
                v-model="bondDetails.point_of_contact_email"
                name="floating_point_of_contact_email"
                id="floating_point_of_contact_email"
                type="text"
                required
              ></md-input>
              <div
                v-if="
                  submitted && !$v.bondDetails.point_of_contact_email.required
                "
                class="invalid-feedback"
              >
                Email is required
              </div>
            </md-field>
          </div>

          <div class="col-span-2">
            <span class="oeno_portfolio_winetitle pt-10"
              >Your Customer Code</span
            >
          </div>
          <div class="col-span-3">
            <md-field class="md-field-small">
              <md-input
                v-model="bondDetails.customer_code"
                name="floating_customer_code"
                id="floating_customer_code"
                type="text"
                required
              ></md-input>
              <div
                v-if="submitted && !$v.bondDetails.customer_code.required"
                class="invalid-feedback"
              >
                Your Customer Code is required
              </div>
            </md-field>
          </div>
          <div class="col-span-5">
            <div class="flex justify-center">
              <button
                type="submit"
                @click="submitForm()"
                class="w-48 h-10 oeno_button_black mt-2 align-center"
                >{{ updateStatus ? "Processing...." : "Continue" }}</button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";

import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
  },
  validations() {
    return {
      bondDetails: {
        company_name: { required },
        company_address: { required },
        point_of_contact: { required },
        customer_code: { required },
        point_of_contact_email: { required, email },
      },
    };
  },
  data() {
    return {
      bondDetails: null,
      updateStatus: false,
      orderData: null,
      submitted: false,
    };
  },
  created: function () {
    this.setDefaultData();
  },
  methods: {
    setDefaultData() {
      this.bondDetails = this.$store.state.orderData.bondDetails;
      this.orderData = this.$store.state.orderData;
    },
    submitForm() {
      this.updateStatus = true;

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.updateStatus = false;
        return;
      } else {
        this.orderData.bondDetails = this.bondDetails;

        this.$store.dispatch("storeOrderData", this.orderData).then(() => {
          this.$router.push({
            name: "page--buy--invest-pay",
            params: {
              id: this.$route.params.id,
              source: this.$route.params.source,
              sell_size: this.$route.params.sell_size,
            },
          });
        });
      }
    },
  },
};
</script>
